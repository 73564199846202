import React, { Component } from 'react';

import AppPromotion from './AppPromotion';

// Styles
import styles from './prefooter.module.scss';
import Inner from './Inner';

class Prefooter extends Component {
  render() {
    const isApp = window.app;
    return (
      <div className={styles.prefooter}>
        <Inner>
          { !isApp && (<AppPromotion />) }
        </Inner>
      </div>
    );
  }
}

export default Prefooter;
