import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppLink from 'views/containers/AppLink/AppLink';

// Constants
import { routes, secondRoutes } from 'constants/routes';

// Icons
import Logo from 'assets/icons/logo.svg';
import MenuIcon from 'assets/icons/menu-icon.svg';
import ChevronRight from 'assets/icons/chevron-right.svg';

// Styles
import styles from './header.module.scss';
import Inner from './Inner';

const Header = ({
  menuOpen,
  toggleMenu,
}) => {
  const ariaLabel = menuOpen ? 'Luk menuen' : 'Åben menuen';
  const headerClass = classNames(styles.wrapper, {
    [styles.navOpen]: menuOpen,
  });
  return (
    <>
      <div className={styles.placeholder} />
      <header className={headerClass}>
        <Inner>
          <AppLink to="/" className={styles.logo}>
            <Logo />
          </AppLink>
          <button type="button" aria-label={ariaLabel} className={styles.menuToggler} onClick={toggleMenu}>
            <MenuIcon />
          </button>
        </Inner>

        {/* Overlay */}
        <button type="button" className={styles.navOverlay} onClick={toggleMenu} aria-label={ariaLabel} />

        <nav className={styles.nav}>
          {/* Close Button in the Menu */}
          <button type="button" className={styles.navContainerButton} onClick={toggleMenu} aria-label="Luk menuen" />
          {/* The Container coming in */}
          <div className={styles.navContainer}>

            <div className={styles.navHeader} />
            <div className={styles.navMain}>
              <div className={styles.navPrimary}>
                <h4 className={styles.navLabel}>Menu</h4>
                <ul className={styles.navList}>
                  {Object.entries(routes).map(route => (
                    <li key={route[0]}>
                      <AppLink to={route[1]} onClick={toggleMenu}>
                        {route[0]}
                      </AppLink>
                    </li>
                  ))}
                  <li>
                    <a
                      href="https://bygherreforeningen.dk/ab18/ab-hotline/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      AB18-Hotline
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.navSecondary}>
                <ul className={styles.navListSecond}>
                  {Object.entries(secondRoutes).map(route => (
                    <li key={route[0]}>
                      <AppLink to={route[1]} onClick={toggleMenu}>
                        {route[0]}
                      </AppLink>
                    </li>
                  ))}
                  <li>
                    <a
                      href="https://poulschmith.dk/personer/bo-schmidt-pedersen"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kontakt
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <footer className={styles.navFooter}>
              <p>Idé og udvikling af
                <Logo />
              </p>
            </footer>
          </div>
        </nav>
      </header>
    </>
  );
};

Header.propTypes = {
  menuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func,
};

export default Header;
